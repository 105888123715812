import React from "react"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Hero from "../components/hero/hero"
import MyServices from "../components/my-services/my-services"
import ContactBanner from "../components/contact-banner/contact-banner"
import Testimonials from "../components/testimonials/testimonials"

function Services() {
  return (
    <Layout>
      <SEO title="Home" />
      <Hero
        title="What We Do"
        image="https://images.unsplash.com/photo-1553704582-79417a629e83?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
      ></Hero>
      <MyServices />
      <ContactBanner background="#f1f1f1"/>
    </Layout>
  )
}

export default Services
