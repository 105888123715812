import React from "react"
import Button from "../../../../library/button/button"

import serviceBlockStyles from "./service-block.module.scss"

function ServiceBlock(props) {
  const { reverse, image, title, description } = props

  return (
    <div className={serviceBlockStyles.container}>
      <div className={serviceBlockStyles.image_wrapper}>Images Goes Here</div>
      <div className={serviceBlockStyles.content_wrapper}>
        <h1 className={serviceBlockStyles.title}>{title}</h1>
        <div className={serviceBlockStyles.underline}></div>
        <p>{description}</p>
        <br />
        <Button
          onClick={() => (window.location.href = "/contact-us")}
          label="Request More Info"
        />
      </div>
    </div>
  )
}

export default ServiceBlock
