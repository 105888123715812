import React from "react"
import Section from "../../library/section/section"
import SectionHeader from "../section-header/section-header"
import ServiceBox from "../service-box/service-box"
import ServiceBlock from "./components/service-block/service-block"

import myServiceStyles from "./my-services.module.scss"

function MyServices() {
  return (
    <div className={myServiceStyles.container}>
      <Section>
        <SectionHeader header="Our Services" />
        <div className={myServiceStyles.services_container}>
          <div className={myServiceStyles.service_wrapper}>
            <h3>Full Service</h3>
            <p>
              From start to finish, we help create a theme, budget overview,
              plan your event and coordinate with vendors, while making sure
              everything goes as planned on the day of the event. This includes
              consultation and 5 in person or virtual planning updates, with
              unlimited email and phone communication.{" "}
            </p>
          </div>
          <div className={myServiceStyles.service_wrapper}>
            <h3>Social event design</h3>
            <p>
              Whether it’s a birthday party, gender reveal, baby shower, holiday
              party, or any other special event, we book vendors, plan and
              execute your event; with set-up only on event day. This includes
              consultation and 3 in person or virtual planning updates, with
              unlimited email and phone communication.
            </p>
          </div>
          <div className={myServiceStyles.service_wrapper}>
            <h3>One-time consultation $50</h3>
            <p>
              One in-person or virtual one hour meeting to help give you the
              tools to coordinate your own event.
            </p>
          </div>
        </div>
      </Section>
      <Section bgImage="https://images.unsplash.com/photo-1496707783091-854ecd84ae5e?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1350&q=80">
        <div className={myServiceStyles.box}>
          <div className={myServiceStyles.service_wrapper}>
            <h3>A LA CARTE:</h3>
            <p>-Day of coordination</p>
            <p>-Theme and design</p>
            <p>-Venue search</p>
            <p>-Vendor referrals $50/each</p>
          </div>
          <div className={myServiceStyles.service_wrapper}>
            <h3>Fees: $40/hr:</h3>

            <p>Each celebration is unique, so totals vary per event.</p>
          </div>
        </div>
      </Section>
    </div>
  )
}

export default MyServices
